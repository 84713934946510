<template>
  <div>
    <div class="lesson-content">
      <b-card title="ankieta">
        <b-card-text>
          <Quiz v-if="survey"
              :quiz="survey"
              :scored="false"
          />
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import Quiz from "@/views/quiz/Quiz";
import {BCard, BCardText} from "bootstrap-vue";

export default {
  name: 'starterPoll',
  components: {
    Quiz,
    BCardText,
    BCard,
  },
  data() {
    return {
      survey: null,
    }
  },
  created() {
    this.$http.get(`${this.$store.state.apiDomain}/api/individual-courses/survey/` + this.$route.params.id).then(response => {
      if(!response.data.survey) {
        this.$router.push('/');
      }
      this.survey = response.data.survey
    })
  },
}
</script>

<style scoped>

</style>
